<template>
  <div>
    <div class="container">
      <div class="list list3">
        <div class="listitem3">
          <div class="listbox">
            <img :src="picture_url" class="iconewm" />           
          </div>         
        </div>
      </div>
      <div class="list list2">
        <div class="listitem2">
          <div class="listbox">
            <div class="name">是否去过疫区</div>
          </div>
          <div class="ts">{{yqtxtflag}}</div>
          <div class="listbox2">
            <div v-if="yqflag == false"><img src="@/assets/kgoff.png" @click="onKg" class="iconkg" /></div>
            <div v-if="yqflag == true"><img src="@/assets/kgon.png" @click="onKg" class="iconkg" /></div>
          </div>        
        </div>
      </div>
      <div class="list list2">
        <div class="listitem2">
          <div class="listbox">
            <div class="name">体温是否正常</div>
          </div>
          <div class="ts">{{twtxtflag}}</div>
          <div class="listbox2">
            <div v-if="flag == false"><img src="@/assets/kgoff.png"  @click="onTw" class="iconkg" /></div>
            <div v-if="flag == true"><img src="@/assets/kgon.png"  @click="onTw" class="iconkg" /></div>
          </div>        
        </div>
      </div>
      
      <div class="list_ll list2">
        <div class="ll">
          <div class="button primary mt" @click="submit">提交</div>         
        </div>
        
      </div>

    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {
    let { token } = this.$route.query;
    this.token = token;
    var zpflag = token.substring(0,3);
    //alert(zpflag);
    if(zpflag!=='zh_'){
      let { data } = await this.axios.post(
        '/ctid/enterpriseService/getImg',
        qs.stringify({
          token: this.token
        })
      );
      if(data.code=='1'){
        //Toast(data.msg);
        this.picture_url = 'data:image/png;base64,' + data.imgsrc;  
      }
    }
    
  },
  data() {
    return {
      data: {
        kgflag: false
      },
      token:'',
      picture_url:'/img/zwzp.png',
      picname:'',
      yqtxtflag: '否',
      twtxtflag: '是',
      yqflag: false,
      txzflag: false,
      rlflag: false,
      flag: true
    };
  },
  methods: {
    onKg() {
      this.yqflag=!this.yqflag;
      if(this.yqflag){
        this.yqtxtflag='是';
      }else{
        this.yqtxtflag='否';
      }
     
    },
    onTw() {
      this.flag=!this.flag;
      if(this.flag){
        this.twtxtflag='是';
      }else{
        this.twtxtflag='否';
      }
  
    },    
    async submit(){
      var yqtxt = '';
      var twtxt = '';
      if(this.yqflag){
        yqtxt='1';
      }else{
        yqtxt='0';
      }
      if(this.flag){
        twtxt='1';
      }else{
        twtxt='0';
      }
      let { data } = await this.axios.post(
        '/ctid/enterpriseService/set_info',
        qs.stringify({
          token: this.token,
          yq: yqtxt,
          tw: twtxt
        })
      );
      if (data.code==='1') {
        Dialog.confirm({
          message: "<img src='/img/cg.png' width=100px height=60px/></br>"+data.msg+",是否离开！"
        }).then(async () => {
          window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
        });
      }else {
        Dialog.confirm({
          message: "<img src='/img/sb.png' width=100px height=60px/></br>"+data.msg+",是否离开！"
        }).then(async () => {
          window.WeixinJSBridge.invoke('closeWindow',{},function(res){});
        });
      }      
    }
    
  }
};
</script>

<style lang="less" scoped>
.icon1 {
  width: 64px;
  height: 64px;
  display: block;
}
.bg {
  height: 503px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  &.list3 {
    margin-top: 50px;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .iconkg {
      width: 100px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 38px;
    }
    .input {
      margin-left: 15px;
      border: 0;
      color: #333;
      margin-top: 34px;
      font-size: 32px;
    }
  }
  .listitem2 {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    display: flex;
    &.mt {
      height: 270px;
    }
    .listbox {
      flex: 1;
      overflow: hidden;
      .red {
        color: rgb(180, 4, 4);
      }
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 23px;
        margin-left: 34px;
      }
      .name1 {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 52px;
        margin-left: 34px;
      }
      .unames {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 30px;
        margin-left: 36px;
      }
      .unames1 {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 10px;
        margin-left: 36px;
      }
      
    }
    .ts {
      height: 60px;
      width: 50px;
      line-height: 60px;
      overflow: hidden;
      font-size: 30px;
      margin-top: 22px;
      margin-right: 0px;
      display: block;
      float: right;
      
    }
    .listbox1 {
      flex: 1;
      overflow: hidden;
      .unames {
        height: 60px;
        line-height: 60px;
        font-size: 28px;
        color: #c90c0c;
        margin-top: 22px;
        margin-right: 34px;
        float: right;
      }
      .iconkg {
        width: 100px;
        margin-top: 22px;
        margin-right: 34px;
        display: block;
        float: right;
      }
      
      .iconkg1 {
        margin-top: 34px;
        width: 320px;   
        height: 203px;  
        float: right;
        margin-right: 34px;
      }

      
    }
    .listbox2 {
      overflow: hidden;
      .unames {
        height: 60px;
        line-height: 60px;
        font-size: 28px;
        color: #c90c0c;
        margin-top: 22px;
        margin-right: 34px;
        float: right;
      }
      .iconkg {
        width: 120px;
        margin-top: 10px;
        margin-right: 20px;
        display: block;
        float: right;
      }     
    }
    
  }
  .listitem3 {
    overflow: hidden;
    height: 550px;
    margin-bottom: 20px;
    border-radius: 8px;
    display: flex;
    .listbox {
      flex: 1;
      overflow: hidden;
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 52px;
        margin-left: 280px;
      }  
      .iconewm {
        margin-top: 80px;
        margin-left: 205px;
        border-radius: 8px;
        width: 300px;   
        height: 400px;  
      }  
      
      .ll {
        width: 90%;
        margin: 0px auto;
      }
      
    }

    
  }
  
}
.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
  }
  
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 144px;
    height: 144px;
    border-radius: 144px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 44px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 66px;
      color: #fff;
      font-size: 48px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
</style>